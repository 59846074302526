<template>
  <main role="main" class="container">
    <article>
      <div class="row">
        <div class="col">
          <a href="#" @click.prevent="$router.go(-1)">
            <img src="@/assets/IC_Arrow_Left_pink_secondary.svg" alt="back" />
            <span class="back-link">{{ $t("components.actions.back") }}</span>
          </a>
        </div>
        <div class="col">
          <employee-list-field
            :form="form"
            name="employee"
            :initValue="initialEmployeeCode"
          ></employee-list-field>
        </div>
      </div>

      <template v-if="$isset(form.employee)">
        <div class="d-flex pb-2 table-responsive">
          <card-info-component
            :title="$t('pages.planning.widgets.hour_balance')"
            :value="employeeHours"
            :is-loading="initializing"
            v-if="activeHoursPack"
            class="mr-4"
          />

          <card-info-component
            :title="$t('pages.dashboard.widgets.vacation_days')"
            :value="employeeDayVacations"
            :is-loading="initializing"
          />
        </div>

        <div class="row">
          <div class="col">
            <vue-button
              class="btn btn-secondary m-2 float-right"
              :title="$t('pages.planing-team-validate.add_overtime')"
              @click.prevent="addOvertime"
            ></vue-button>

            <vue-button
              class="btn btn-secondary m-2 float-right"
              :title="$t('pages.planing-team-validate.add_workshift')"
              @click.prevent="addWorkShift"
            ></vue-button>

            <vue-button
              class="btn btn-secondary m-2 float-right"
              :title="$t('pages.planing-team-validate.add_absence')"
              @click.prevent="addAbsence"
            ></vue-button>
          </div>
        </div>

        <div class="row mb-2 mt-2">
          <div class="col">
            <div class="card box-shadow employee-data">
              <div class="card-body p-0">
                <div class="row">
                  <div class="col-md-5">
                    <div class="form-group m-3">
                      <label class="label-description">{{
                        $t("pages.planing-team-validate.employee_name")
                      }}</label>
                      <div>
                        <div class="description">{{ employeeName }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group m-3">
                      <label class="label-description">{{
                        $t("pages.planing-team-validate.employee_id")
                      }}</label>
                      <div>
                        <div class="description">{{ employeeCode }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="card box-shadow">
              <div class="card-body">
                <div class="row mb-3">
                  <div class="col col-sm-8 col-md-8 col-lg-8">
                    <suc-date-period-component
                      @[datePeriodChangedEvent]="datePeriodChanged"
                      :type="typePeriod"
                    ></suc-date-period-component>
                  </div>
                  <div class="col col-sm-4 col-md-4 col-lg-4 text-right">
                    <a href="#" class="p-0 pl-2 float-right btn-refresh" @click.prevent="init"
                      ><i class="fas fa-sync-alt"></i
                    ></a>
                  </div>
                </div>

                <article class="data-load" :class="{ 'sk-loading': initializing }">
                  <h3 class="mb-4">
                    {{ $t("pages.planing-team-validate.data") }}
                    <span class="float-right" v-if="activeHourForApprove">{{
                      `${$t("pages.planing-team-validate.real")}: ${summaryDayApprovedHour}h`
                    }}</span>
                  </h3>

                  <sk-spinner v-if="initializing"></sk-spinner>

                  <suc-vertical-table-component
                    :row-headers="rowHeaders"
                    :columnsData="columnsData"
                    :cells="cells"
                  >
                    <template #cell="props">
                      <template v-if="props.cell.key === 'weekDayName'">
                        <span
                          :class="{
                            'week-end': props.cell.column.data.day.weekend,
                            'font-weight-bold': true,
                          }"
                          >{{ props.cell.value }}</span
                        >
                      </template>
                      <template v-else-if="'dayType' === props.cell.key">
                        <plan-week-or-monthItem-table-component
                          :employeeData="props.cell.column.data.employeePlan"
                          :employee="employeePlanData.employee"
                        />
                      </template>
                      <template
                        v-else-if="
                          props.cell.key === 'date' && props.cell.value !== props.cell.defaultValue
                        "
                      >
                        <formatted-date-component
                          :val="props.cell.value"
                          :onlyDay="true"
                          class="font-weight-light font-weight-italic"
                        />
                      </template>
                      <template
                        v-else-if="
                          'planned' === props.cell.key &&
                          props.cell.value !== props.cell.defaultValue &&
                          !props.cell.column.data.fired
                        "
                      >
                        <formatted-number-component
                          :val="props.cell.value"
                          @click="plannedHourClicked(props.cell.column.data.employeePlan)"
                          :class="['planned-hour']"
                        />
                      </template>
                      <template
                        v-else-if="
                          'biometric' === props.cell.key &&
                          props.cell.value !== props.cell.defaultValue &&
                          !props.cell.column.data.employeePlan.fired
                        "
                      >
                        <div
                          class="hour-biometric"
                          @click="addBiometricHours(props.cell.column.data)"
                        >
                          <img
                            src="@/assets/IC_Msg_Sucess.png"
                            alt="valid"
                            v-if="
                              props.cell.column.data.biometric.isValid &&
                              parseInt(props.cell.column.data.biometric.totalMinutes) <= 1440
                            "
                          />
                          <img src="@/assets/IC_Msg_Alert.png" alt="warning" v-else />
                          <formatted-number-component :val="props.cell.value" />
                          <div class="row text-left">
                            <div class="col-4">
                              <img
                                src="@/assets/IC_plan_biometrico_secondary_white.svg"
                                alt="icon"
                              />
                            </div>
                            <div class="col-8 text-right">
                              <formatted-minutes-component
                                :val="props.cell.column.data.biometric.totalMinutes"
                              />
                            </div>
                          </div>
                        </div>
                      </template>
                      <template
                        v-if="
                          props.cell.key === 'real' &&
                          props.cell.value !== props.cell.defaultValue &&
                          !props.cell.column.data.employeePlan.fired
                        "
                      >
                        <div
                          class="hour-real"
                          @click="addRealHours(props.cell.column.data.employeePlan)"
                        >
                          <formatted-number-component :val="props.cell.value" />
                        </div>
                      </template>
                      <template
                        v-if="
                          props.cell.key === 'actions' &&
                          $isset(props.cell.column.data.employeePlan.key) &&
                          !props.cell.column.data.employeePlan.fired
                        "
                      >
                        <div>
                          <img
                            src="@/assets/IC_plan_biometrico_secondary.svg"
                            alt="action"
                            class="mx-2"
                            @click="acceptBiometric(props.cell)"
                            v-if="activeBiometric"
                          />
                          <img
                            src="@/assets/IC_plan_planeado.svg"
                            alt="action"
                            class="mx-2"
                            @click="acceptPlanned(props.cell)"
                            v-if="activeHourForApprove"
                          />
                        </div>
                      </template>
                    </template>
                  </suc-vertical-table-component>

                  <div class="row" v-if="showAcceptAllButtons">
                    <div class="col text-right">
                      <vue-button
                        title="ACEPTAR BIOMETRICOS"
                        class="button-secondary-outline my-1"
                        :uppercase="true"
                        @click="acceptAllBiometrics"
                        v-if="activeBiometric"
                      >
                        <template #body-icon>
                          <img src="@/assets/IC_plan_biometrico_secondary.svg" alt="icon" />
                        </template>
                      </vue-button>

                      <vue-button
                        title="Acceptar Planejat"
                        class="btn-primary my-1 ml-3"
                        :uppercase="true"
                        @click="acceptAllPlanned"
                        v-if="activeHourForApprove"
                      >
                        <template #body-icon>
                          <img src="@/assets/IC_plan_planeado_white.svg" alt="icon" />
                        </template>
                      </vue-button>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </template>
    </article>

    <set-biometric-hour-modal @changed="init" />
    <set-real-hour-modal @changed="init" />
    <copy-hour-confirm-modal @changed="init" />
    <add-work-shift-modal @changed="init" />
    <add-absence-modal @changed="init" />
    <set-planning-hour-modal @changed="init" />
    <add-overtime-modal @changed="init" />
    <edit-overtime-modal @changed="init" />
    <work-shift-detail-modal />
    <day-detail-component />
  </main>
</template>

<script>
import { CardInfoComponent } from "@/pages/components";

import {
  SUC_DATE_PERIOD_CHANGED_EVENT,
  SUC_SELECT_CHANGED_EVENT,
  SucDatePeriodComponent,
  DATE_PERIOD_WEEK,
  VueButton,
  Form,
} from "@/components/form";
import { EmployeePlanData, BiometricHourDayFiltered } from "@/pages/components/data";
import PlanWeekOrMonthItemTableComponent from "@/pages/responsible/planing/general/components/PlanWeekOrMonthItemTableComponent.vue";

import SkSpinner from "@/components/SkSpinner";

import { EmployeeListField } from "@/pages/components/fields";
import { addDays, isEqual, isSunday, isSaturday } from "date-fns";
import { getDay } from "@/utils/functions";

import {
  FormattedDateComponent,
  FormattedNumberComponent,
  FormattedMinutesComponent,
} from "@/components/formatted";
import SucVerticalTableComponent, { Cell, Header } from "@/components/table/vertical-build";
import SetBiometricHourModal from "@/pages/responsible/planing/validate/SetBiometricHourModal.vue";
import SetRealHourModal from "@/pages/responsible/planing/validate/SetRealHourModal.vue";
import CopyHourConfirmModal from "@/pages/responsible/planing/validate/CopyHourConfirmModal.vue";
import AddWorkShiftModal from "@/pages/responsible/planing/validate/AddWorkShiftModal.vue";
import AddAbsenceModal from "@/pages/responsible/planing/validate/AddAbsenceModal.vue";
import AddOvertimeModal from "@/pages/responsible/planing/validate/AddOvertimeModal.vue";
import EditOvertimeModal from "@/pages/responsible/planing/validate/EditOvertimeModal.vue";
import SetPlanningHourModal from "@/pages/responsible/planing/validate/SetPlanningHourModal.vue";

import * as events from "@/pages/responsible/planing/data/events";

import apiPlan from "@/api/plan";
import apiBiometricHours from "@/api/biometric_hours";
import { ModalAlert } from "@/components/modal";

import { mapGetters } from "vuex";
import { WhoAmIMixin } from "@/components/mixins";
import WorkShiftDetailModal from "@/pages/responsible/planing/workshift/components/WorkShiftDetailModal.vue";

import { ValidateDayItem, WeekDay } from "./data/index";
import DayDetailComponent from "@/pages/responsible/planing/general/components/DayDetailComponent.vue";

export default {
  mixins: [WhoAmIMixin],
  name: "PlaningTeamValidatePage",
  layout: "MainLayout",
  middleware: ["auth"],
  components: {
    VueButton,
    SucDatePeriodComponent,
    EmployeeListField,
    SucVerticalTableComponent,
    FormattedDateComponent,
    FormattedNumberComponent,
    SetBiometricHourModal,
    SetRealHourModal,
    CopyHourConfirmModal,
    AddWorkShiftModal,
    AddAbsenceModal,
    AddOvertimeModal,
    EditOvertimeModal,
    SkSpinner,
    CardInfoComponent,
    PlanWeekOrMonthItemTableComponent,
    SetPlanningHourModal,
    FormattedMinutesComponent,
    WorkShiftDetailModal,
    DayDetailComponent,
  },
  data() {
    return {
      form: new Form({
        data: {
          employee: null,
        },
      }),
      startDate: null,
      endDate: null,
      initializing: false,
      columnsData: [],
      rawPlanEmployeeType: [],
      rawBiometricHourDayFiltered: [],
      employeePlanData: null,
      employeeBiometricHourDayFiltered: [],
    };
  },
  computed: {
    ...mapGetters("auth", ["activeHoursPack", "activeBiometric", "activeHourForApprove"]),
    initialEmployeeCode() {
      return this.$route.params.code;
    },
    typePeriod() {
      return DATE_PERIOD_WEEK;
    },
    selectChangedEvent() {
      return SUC_SELECT_CHANGED_EVENT;
    },
    datePeriodChangedEvent() {
      return SUC_DATE_PERIOD_CHANGED_EVENT;
    },
    employeeName() {
      return this.$isset(this.form.employee) ? this.form.employee.fullName : "";
    },
    employeeCode() {
      return this.$isset(this.form.employee) ? this.form.employee.employeeCode : "";
    },
    employeeHours() {
      return this.$isset(this.employeePlanData) ? this.employeePlanData.employee.hourBalance : 0;
    },
    employeeDayVacations() {
      return this.$isset(this.employeePlanData)
        ? this.employeePlanData.employee.holidaysRemaining
        : 0;
    },
    showAcceptAllButtons() {
      return (
        this.$isset(this.rawPlanEmployeeType.data) &&
        this.rawPlanEmployeeType.data.filter((x) => !x.fired).filter((x) => this.$isset(x.key))
          .length > 0 &&
        (this.activeBiometric || this.activeHourForApprove)
      );
    },
    summaryDayApprovedHour() {
      if (this.$isset(this.rawPlanEmployeeType.data)) {
        return _.sumBy(this.rawPlanEmployeeType.data, (x) => x.approvedHours).toFixed(2);
      }
      return 0;
    },
    cells() {
      let cells = [];
      cells.push(
        new Cell({
          key: "weekDayName",
          valueKey: "day.weekDayName",
          classes: ["text-center", "hour-column"],
        })
      );
      cells.push(
        new Cell({
          key: "date",
          valueKey: "employeePlan.date",
          classes: ["text-center", "hour-column"],
        })
      );
      cells.push(
        new Cell({
          key: "dayType",
          valueKey: "employeePlan.dayType",
          classes: ["text-center", "hour-column"],
        })
      );
      cells.push(
        new Cell({
          key: "planned",
          valueKey: "employeePlan.planningHours",
          classes: ["text-center", "hour-column"],
        })
      );
      if (this.activeBiometric) {
        cells.push(
          new Cell({
            key: "biometric",
            valueKey: "employeePlan.dayBiometricHours",
            classes: ["text-center", "hour-column"],
          })
        );
      }
      if (this.activeHourForApprove) {
        cells.push(
          new Cell({
            key: "real",
            valueKey: "employeePlan.approvedHours",
            classes: ["text-center", "hour-column"],
          })
        );
      }
      cells.push(
        new Cell({
          key: "actions",
          valueKey: "actions",
          classes: ["text-center", "hour-column"],
        })
      );
      return cells;
    },
    rowHeaders() {
      let headers = [];
      headers.push(
        new Header({
          classes: ["type"],
        })
      );
      headers.push(
        new Header({
          name: this.$t("pages.planing-team-validate.days"),
          classes: ["type"],
        })
      );
      headers.push(
        new Header({
          name: this.$t("pages.planing-team-validate.icons"),
          classes: ["type"],
        })
      );
      headers.push(
        new Header({
          name: this.$t("pages.planing-team-validate.planned"),
          classes: ["type"],
        })
      );
      if (this.activeBiometric) {
        headers.push(
          new Header({
            name: this.$t("pages.planing-team-validate.biometric"),
            classes: ["type"],
          })
        );
      }
      if (this.activeHourForApprove) {
        headers.push(
          new Header({
            name: this.$t("pages.planing-team-validate.real"),
            classes: ["type"],
          })
        );
      }
      if (this.showAcceptAllButtons) {
        headers.push(
          new Header({
            name: this.$t("pages.planing-team-validate.actions"),
            classes: ["type"],
          })
        );
      }
      return headers;
    },
  },
  watch: {
    "form.employee"() {
      this.reInit = true;
      this.init();
    },
  },
  methods: {
    async init() {
      if (
        !this.initializing &&
        this.$isset(this.form.employee) &&
        this.$isset(this.startDate) &&
        this.$isset(this.endDate)
      ) {
        this.initializing = true;

        if (this.activeBiometric) {
          const dataBiometric = await apiBiometricHours.getBiometricHoursByDays({
            employeeCode: this.form.employee.employeeCode,
            from: this.startDate,
            to: this.endDate,
          });
          this.rawBiometricHourDayFiltered = dataBiometric.data;
          this.employeeBiometricHourDayFiltered = this.rawBiometricHourDayFiltered.map((x) =>
            new BiometricHourDayFiltered().parse(x)
          );
        }

        const { data } = await apiPlan.getPlanEmployeeType({
          code: this.form.employee.employeeCode,
          from: this.startDate,
          to: this.endDate,
        });

        this.rawPlanEmployeeType = data;
        this.employeePlanData = new EmployeePlanData().parse(data);

        let columnData = [];
        for (let i = 0; i < 7; i++) {
          let day = addDays(this.startDate, i);
          let findData = this.employeePlanData.data.find((x) => isEqual(x.date, day));
          let biometricFilteredData = this.employeeBiometricHourDayFiltered.find((x) =>
            isEqual(x.day, day)
          );
          if (this.$isset(findData)) {
            columnData.push(
              new ValidateDayItem({
                day: new WeekDay({
                  weekend: isSaturday(day) || isSunday(day),
                  weekDayName: this.$t(`components.date.day_short[${getDay(day)}]`),
                }),
                biometric: biometricFilteredData,
                employeePlan: findData,
              })
            );
            // findData = Object.assign(
            //   {
            //     weekend: isSaturday(day) || isSunday(day),
            //     weekDayName: this.$t(`components.date.day_short[${getDay(day)}]`),
            //   },
            //   findData,
            //   { biometric: biometricFilteredData }
            // );
            // columnData.push(findData);
          }
        }
        this.columnsData = columnData;

        this.initializing = false;
      }
    },
    datePeriodChanged({ start, end }) {
      this.startDate = this.endDate = null;
      this.startDate = start;
      this.endDate = end;
      this.init();
    },
    addWorkShift() {
      EventBus.fire(events.SHOW_WORK_SHIFT_ADD_MODAL, {
        employee: this.$deepCopy(this.form.employee),
      });
    },
    addAbsence() {
      EventBus.fire(events.SHOW_ABSENCE_ADD_MODAL, {
        employee: this.$deepCopy(this.form.employee),
      });
    },
    addOvertime() {
      EventBus.fire(events.SHOW_OVERTIME_ADD_MODAL, {
        employee: this.$deepCopy(this.form.employee),
      });
    },
    plannedHourClicked(employeeData) {
      //check if hour exist, if no error should be for each day.
      if (this.$isset(employeeData.key)) {
        EventBus.fire(events.SHOW_PLANNING_EDIT_HOUR_MODAL, {
          ...employeeData,
        });
      } else {
        this._errorEmotyPlannigHourKey();
      }
    },
    addRealHours(employeeData) {
      //check if hour exist, if no error should be for each day.
      if (this.$isset(employeeData.key)) {
        EventBus.fire(events.SHOW_REAL_HOUR_MODAL, this.$deepCopy(employeeData));
      } else {
        this._errorEmotyPlannigHourKey();
      }
    },
    addBiometricHours(validateDayItem) {
      //check if hour exist, if no error should be for each day.
      if (this.$isset(validateDayItem.employeePlan.key)) {
        EventBus.fire(events.SHOW_BIOMETRIC_HOUR_MODAL, {
          employeePlan: validateDayItem.employeePlan,
          biometric: validateDayItem.biometric,
        });
      } else {
        this._errorEmotyPlannigHourKey();
      }
    },
    _errorEmotyPlannigHourKey() {
      ModalAlert.error({
        message: this.$t("pages.planing-team-validate.planning_key_hour_empty"),
      });
    },
    acceptBiometric(cell) {
      EventBus.fire(events.SHOW_CONFIRM_HOUR_MODAL, {
        validateDayItems: this.$deepCopy(cell.column.data),
        type: "biometric",
        title: this.$t("pages.planing-team-validate.hours_copy_biometric"),
      });
    },
    acceptPlanned(cell) {
      EventBus.fire(events.SHOW_CONFIRM_HOUR_MODAL, {
        validateDayItems: this.$deepCopy(cell.column.data),
        type: "planned",
        title: this.$t("pages.planing-team-validate.hours_copy_planned"),
      });
    },
    acceptAllBiometrics() {
      EventBus.fire(events.SHOW_CONFIRM_HOUR_MODAL, {
        validateDayItems: this.$deepCopy(this.columnsData),
        type: "biometric",
        title: this.$t("pages.planing-team-validate.hours_copy_biometric_all"),
      });
    },
    acceptAllPlanned() {
      EventBus.fire(events.SHOW_CONFIRM_HOUR_MODAL, {
        validateDayItems: this.$deepCopy(this.columnsData),
        type: "planned",
        title: this.$t("pages.planing-team-validate.hours_copy_planned_all"),
      });
    },
  },
};
</script>

<style lang="scss">
.hour-column {
  min-width: 50px !important;
  max-width: 100px;
}

.week-end {
  color: #be340a;
}

.employee-data {
  & .form-group .label-description {
    color: $color-gray;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19.6px;
    text-transform: uppercase;
    margin: 0;
  }

  & .form-group .description {
    color: $color-secondary;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22.4px;
  }
}

.type {
  background-color: $color-gray-2;
}

.planned-hour {
  border: 1px solid $color-gray;
  padding: 8px 24px;
  border-radius: $border-radius;
}

.hour-real,
.hour-biometric {
  color: $color-white;
  padding: 8px 4px;
}

.hour-biometric {
  border: 1px solid $color-secondary;
  border-radius: $border-radius;
  background-color: $color-secondary;
}

.hour-real {
  border: 1px solid $color-primary;
  border-radius: $border-radius;
  background-color: $color-primary;
}

.table-striped {
  tbody tr:nth-of-type(#{$table-striped-order}) {
    background-color: $color-gray-3;
  }
}

.table > thead > tr > td {
  color: $color-primary;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
}

.button-secondary-outline {
  border: 1px solid $color-secondary;
  border-radius: $border-radius;
  color: $color-secondary;
  text-transform: uppercase;
}

.back-link {
  color: $color-secondary;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22.4px;
}
</style>
