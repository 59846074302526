<template>
  <vue-modal
    :modal-title="$t('pages.planing-team-validate.hours_biometric')"
    :show-spinner="isLoading"
    :show-modal="showModal"
    :modalExtraLarge="true"
    :loading-secondary-footer-button="isLoading"
    @[primaryEvent]="onPrimaryButtonClicked"
    @[secondaryEvent]="onCloseButtonClicked"
    @[closeEvent]="onCloseButtonClicked"
  >
    <template #body>
      <div class="form-group">
        <label for="biometric-hour-field">{{ $t("pages.planing-team-validate.hours") }}</label>
        <input type="number" class="form-control" id="biometric-hour-field" v-model="hourValue" />
      </div>
      <div class="row">
        <div class="col">
          <vue-data-table
            :classTable="classTable"
            :table-columns="columns"
            :table-rows="rows"
            :is-loading="isLoading"
            :showRefreshButton="false"
            :showRowTableAction="false"
            :showFooterTable="showFooterTable"
            :empty-rows-message="$t('components.table.no_data')"
          >
            <template #table-row="props">
              <template v-if="props.cell.getColumn.id == 'name'">
                {{ props.cell.getValue }}
              </template>

              <template v-else-if="props.cell.getColumn.id == 'biometricHour.biometricType'">
                <div v-if="props.cell.getValue == 0">
                  {{ $t("pages.planing-team.biometric.enter") }}
                </div>
                <div v-else>{{ $t("pages.planing-team.biometric.exit") }}</div>
              </template>
              <template v-if="props.cell.getColumn.id == 'biometricHour.biometricDateWithTime'">
                <formatted-date-component
                  :val="props.cell.getValue"
                  :withTime="true"
                  class="font-weight-light font-weight-italic"
                />
              </template>
            </template>
            <template #table-tfoot-row="props">
              <template v-if="props.column.id == 'biometricHour.biometricDateWithTime'">
                <div class="row">
                  <div class="col">
                    <formatted-minutes-component :val="biometric.totalMinutes" />
                  </div>
                  <div class="col" @click="copyHoursToField">
                    <img
                      src="@/assets/IC_plan_biometrico_secondary.svg"
                      alt="action"
                      class="mx-2"
                    />
                    {{ $t("pages.planing-team.biometric.use_this_time") }}
                  </div>
                </div>
              </template>
            </template>
          </vue-data-table>
        </div>
      </div>
    </template>
  </vue-modal>
</template>

<script>
import VueModal, {
  ModalAlert,
  CLOSE_BUTTON_CLICKED_MODAL_EVENT,
  PRIMARY_BUTTON_CLICKED_MODAL_EVENT,
  SECONDARY_BUTTON_CLICKED_MODAL_EVENT,
} from "@/components/modal";
import VueDataTable, { Column } from "@/components/table";
import {
  FormattedDateComponent,
  // FormattedNumberComponent,
  FormattedMinutesComponent,
} from "@/components/formatted";
import { SHOW_BIOMETRIC_HOUR_MODAL } from "@/pages/responsible/planing/data/events";
import api from "@/api/plan";
import * as Sentry from "@sentry/vue";
import { copyMinutesToHoursField } from "@/utils/functions";

export default {
  name: "SetBiometricHourModal",
  components: {
    VueModal,
    VueDataTable,
    FormattedDateComponent,
    FormattedMinutesComponent,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      hourValue: "",
      employeePlan: null, // EmployeePlan
      biometric: null, // BiometricHourDayFiltered
      rows: [],
      classTable: ["table"],
    };
  },
  created() {
    EventBus.listen(SHOW_BIOMETRIC_HOUR_MODAL, ({ employeePlan, biometric }) => {
      this.employeePlan = employeePlan;
      this.biometric = biometric;
      this.showModal = true;
    });
  },
  watch: {
    async showModal(val) {
      if (val) {
        this.isLoading = true;

        if (this.employeePlan.dayBiometricHours > 0) {
          this.hourValue = this.employeePlan.dayBiometricHours;
        }

        var rows = [];
        this.biometric.hours.forEach((element) => {
          rows.push({
            biometricHour: element,
            name: element.establishmentName ?? element.companyNameDisplayName,
          });
        });
        this.rows = rows;

        this.isLoading = false;
      }
    },
  },
  beforeDestroy() {
    EventBus.off(SHOW_BIOMETRIC_HOUR_MODAL);
  },
  computed: {
    primaryEvent() {
      return PRIMARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    secondaryEvent() {
      return SECONDARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    closeEvent() {
      return CLOSE_BUTTON_CLICKED_MODAL_EVENT;
    },
    columns() {
      let columns = [];

      columns.push(
        new Column({
          id: "name",
          name: "pages.planing-team.biometric.place_name",
        })
      );
      columns.push(
        new Column({
          id: "biometricHour.biometricType",
          name: "pages.planing-team.biometric.hour_type",
        })
      );
      columns.push(
        new Column({
          id: "biometricHour.biometricDateWithTime",
          name: "pages.planing-team.biometric.time",
        })
      );
      return columns;
    },
    showFooterTable() {
      return this.biometric?.isValid && this.rows.length > 0 ? true : false;
    },
  },
  methods: {
    async onPrimaryButtonClicked() {
      if (!this.$isset(this.hourValue) || this.hourValue == "") {
        ModalAlert.error({ message: this.$t("pages.permission.wrong_hours") });
        return;
      }
      this.isLoading = true;

      Sentry.setContext("Data", {
        employeePlan: {
          ...this.employeePlan,
        },
        sendData: { hourValue: this.hourValue, key: this.employeePlan.key },
      });

      api
        .setDayBiometricHours([
          {
            key: this.employeePlan.key,
            hour: `${this.hourValue}`,
          },
        ])
        .then(() => {
          this.$emit("changed");
          this.clear();
          this.showModal = false;
        })
        .catch((error) => {
          if (error.message.toLowerCase() === "network error") {
            ModalAlert.error({ message: this.$t("components.messages.network_error") });
          } else {
            //unsigned error
            Sentry.captureException(error);
            ModalAlert.error({ message: this.$t("components.messages.error") });
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    onSecondaryButtonClicked() {
      this.clear();
    },
    onCloseButtonClicked() {
      this.clear();
      this.showModal = false;
    },
    clear() {
      this.hourValue = "";
    },
    copyHoursToField() {
      this.hourValue = copyMinutesToHoursField(this.biometric.totalMinutes);
    },
  },
};
</script>
