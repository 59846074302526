export default class WeekDay {
  /**
   * @param {Boolean} weekend
   * @param {string} weekDayName
   */
  constructor({ weekend, weekDayName }) {
    this.weekend = weekend;
    this.weekDayName = weekDayName;
  }
}
