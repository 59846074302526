import { isset } from "@/utils/functions";

export default class Cell {
  constructor({ key, valueKey, column, classes, defaultValue = "-" }) {
    this._key = key;
    this._valueKey = valueKey;
    this._column = column;
    this._classes = classes;
    this._defaultValue = defaultValue;
  }

  get key() {
    return this._key;
  }

  get valueKey() {
    return this._valueKey;
  }

  get column() {
    return this._column;
  }

  get classes() {
    return this._classes;
  }

  get defaultValue() {
    return this._defaultValue;
  }

  get value() {
    return isset(this._column.data) ? _.get(this._column.data, this.valueKey) : this.defaultValue;
  }

  get getValue() {
    return this.value;
  }
}
