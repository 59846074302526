<template>
  <div class="table-responsive">
    <table class="table table-striped">
      <tbody>
        <tr v-for="(rowHeader, header_index) in rowHeaders" :key="rowHeader.name">
          <td :class="rowHeader.classes">{{ rowHeader.name }}</td>
          <td
            v-for="(column, column_index) in prepareData"
            :key="`col_data_${column_index}`"
            :class="column.cellByIndex(header_index).classes"
          >
            <slot name="cell" :cell="column.cellByIndex(header_index)">
              <span>{{ column.cellByIndex(header_index).value }}</span>
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { Column, Cell, Header } from "./index";

export default {
  name: "SucVerticalTableComponent",
  props: {
    rowHeaders: {
      type: Array,
      validator: function (headers) {
        for (let header of headers) {
          if (!(header instanceof Header)) {
            console.error("headers should include only String instance");
            return false;
          }
        }
        return true;
      },
      required: true,
    },
    cells: {
      type: Array,
      validator: function (cells) {
        for (let cell of cells) {
          if (!(cell instanceof Cell)) {
            console.error("headers should include only String instance");
            return false;
          }
        }
        return true;
      },
      required: true,
    },
    columnsData: {
      type: Array,
      required: true,
    },
  },
  computed: {
    prepareData() {
      let columns = [];
      for (let item of this.columnsData) {
        let column = new Column({ data: item });
        for (let cell of this.cells) {
          column.addCell({
            key: cell.key,
            valueKey: cell.valueKey,
            classes: cell.classes,
            defaultValue: cell.defaultValue,
          });
        }
        columns.push(column);
      }
      return columns;
    },
  },
};
</script>

<style scoped></style>
