export default class Header {
  constructor({ name, classes }) {
    this._name = name;
    this._classes = classes;
  }

  get name() {
    return this._name;
  }

  get classes() {
    return this._classes;
  }
}
