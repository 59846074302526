<template>
  <vue-modal
    :modal-title="$t('pages.planing-team-validate.hours_real')"
    :show-spinner="isLoading"
    :show-modal="showModal"
    :loading-primary-footer-button="isLoading"
    @[primaryEvent]="onPrimaryButtonClicked"
    @[secondaryEvent]="onCloseButtonClicked"
    @[closeEvent]="onCloseButtonClicked"
  >
    <template #body>
      <div class="form-group">
        <label for="biometric-hour-field">{{ $t("pages.planing-team-validate.hours") }}</label>
        <input type="number" class="form-control" id="biometric-hour-field" v-model="hourValue" />
      </div>
    </template>
  </vue-modal>
</template>

<script>
import VueModal, {
  ModalAlert,
  CLOSE_BUTTON_CLICKED_MODAL_EVENT,
  PRIMARY_BUTTON_CLICKED_MODAL_EVENT,
  SECONDARY_BUTTON_CLICKED_MODAL_EVENT,
} from "@/components/modal";

import { SHOW_REAL_HOUR_MODAL } from "@/pages/responsible/planing/data/events";

import * as Sentry from "@sentry/vue";
import api from "@/api/plan";

import { mapGetters } from "vuex";

export default {
  components: {
    VueModal,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      hourValue: "",
      workHour: null,
    };
  },
  created() {
    EventBus.listen(SHOW_REAL_HOUR_MODAL, (workHour) => {
      this.workHour = workHour;
      this.showModal = true;
    });
  },
  watch: {
    async showModal(val) {
      if (val) {
        this.isLoading = true;

        if (this.workHour.approvedHours > 0) {
          this.hourValue = this.workHour.approvedHours;
        }

        this.isLoading = false;
      }
    },
  },
  beforeDestroy() {
    EventBus.off(SHOW_REAL_HOUR_MODAL);
  },
  computed: {
    ...mapGetters("auth", ["activeHoursPack"]),
    primaryEvent() {
      return PRIMARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    secondaryEvent() {
      return SECONDARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    closeEvent() {
      return CLOSE_BUTTON_CLICKED_MODAL_EVENT;
    },
  },
  methods: {
    async onPrimaryButtonClicked() {
      this.isLoading = true;
      let hourValue = parseFloat(this.hourValue);
      let hourPlanning = parseFloat(this.workHour.planningHours);

      Sentry.setContext("Data", {
        workHour: {
          ...this.workHour,
        },
        sendData: { hourValue: hourValue, key: this.workHour.key },
      });

      //if enabled hours pack need to show confirmation
      if (this.activeHoursPack) {
        if (hourValue != 0 && hourValue - hourPlanning <= -4) {
          ModalAlert.error({
            message: this.$t("pages.planing-team-validate.error_hours_real_validation"),
          });
          this.isLoading = false;
          return;
        }

        if (hourValue == hourPlanning || hourValue == 0) {
          this._sendHour();
          return;
        }

        const hourDiff = hourValue - hourPlanning;
        const hourDiffShow = hourDiff > 0 ? hourDiff : hourDiff * -1;
        ModalAlert.confirm({
          message:
            hourValue > hourPlanning
              ? this.$t("pages.planing-team-validate.real_hous_more_planning", {
                  hour: hourDiffShow,
                })
              : this.$t("pages.planing-team-validate.real_hous_less_planning", {
                  hour: hourDiffShow,
                }),
          confirmButtonText: this.$t("components.actions.confirm"),
          cancelButtonText: this.$t("components.actions.close"),
        }).then((result) => {
          if (result.isConfirmed) {
            this._sendHour();
          } else {
            this.isLoading = false;
          }
        });
      } else {
        this._sendHour();
      }
    },
    _sendHour() {
      api
        .setDayApprovedHours([
          {
            key: this.workHour.key,
            hour: `${this.hourValue}`,
            nightHour: `${this.workHour.nightHours}`,
          },
        ])
        .then(() => {
          this.$emit("changed");
          this.clear();
          this.showModal = false;
        })
        .catch((error) => {
          if (error.message.toLowerCase() === "network error") {
            ModalAlert.error({ message: this.$t("components.messages.network_error") });
          } else {
            //unsigned error
            Sentry.captureException(error);
            ModalAlert.error({ message: this.$t("components.messages.error") });
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    onSecondaryButtonClicked() {
      this.clear();
    },
    onCloseButtonClicked() {
      this.clear();
      this.showModal = false;
    },
    clear() {
      this.hourValue = "";
    },
  },
};
</script>
