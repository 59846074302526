export default class ValidateDayItem {
  /**
   * @param {WeekDay} day
   * @param {EmployeePlan} employeePlan
   * @param {BiometricHourDayFiltered} biometric
   */
  constructor({ day, biometric, employeePlan }) {
    this.day = day;
    this.biometric = biometric;
    this.employeePlan = employeePlan;
  }
}
