import Cell from "@/components/table/vertical-build/data/cell";

export default class Column {
  constructor({ data = {}, classes }) {
    this._cells = [];
    this._data = data;
    this._classes = classes;
  }

  get data() {
    return this._data;
  }

  set data(data) {
    this._data = data;
  }

  get cells() {
    return this._cells;
  }

  get classes() {
    return this._classes;
  }

  set classes(classes) {
    this._classes = classes;
  }

  cellByIndex(index) {
    return this.cells[index];
  }

  addCell({ key, valueKey, classes, defaultValue }) {
    this._cells.push(
      new Cell({
        key: key,
        valueKey: valueKey,
        column: this,
        classes: classes,
        defaultValue: defaultValue,
      })
    );
  }
}
