import axios from "axios";
import { buildUrl } from "@/config";
import { formatDate, isset } from "@/utils/functions";

export default {
  getBiometricHours({ employeeCode, from, to, size = 10, key = null }) {
    const params = new URLSearchParams([["size", size]]);
    if (isset(key)) {
      params.append("key", key);
    }
    return axios.get(
      buildUrl(
        `api/v1/biometrichours/by-employee/${employeeCode}/${formatDate(from)}/${formatDate(to)}`
      ),
      { params }
    );
  },

  getBiometricHoursByDays({ employeeCode, from, to }) {
    return axios.get(
      buildUrl(
        `api/v1/biometrichours/by-employee-days/${employeeCode}/${formatDate(from)}/${formatDate(
          to
        )}`
      )
    );
  },
};
